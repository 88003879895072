<template>
  <MNavbar layout="sticky">
    <MMobileNav
      v-if="isLoggedIn"
      :open="mobileNavDisplay"
      @close="toggleMobileNav"
    >
      <template #top>
        <MMobileNavItem href="/" @click="toggleMobileNav">
          {{ $t('siteheader.mobile.home') }}
        </MMobileNavItem>
        <div v-for="item in primaryNavItems" :key="`${item.text}-mobile`">
          <MMobileSubnav
            v-if="item.items"
            :items="item.items"
            @item-selected="toggleMobileNav"
          >
            {{ item.text }}
          </MMobileSubnav>
          <MMobileNavItem
            v-else
            :active="isActive(item)"
            tag="router-link"
            :href="item.href"
            :data-qa-locator="item.qaLocator + '-mobile'"
            @click="toggleMobileNav"
          >
            {{ item.text }}
          </MMobileNavItem>
        </div>
      </template>
      <template #bottom>
        <MMobileNavItem v-if="!isAdmin && isMainBranchStructure" is-button>
          <SwitchPractice block />
        </MMobileNavItem>
        <MMobileNavItem is-button>
          <MButton
            type="button"
            block
            data-qa-locator="header-logout-button-mobile"
            button-style="secondary"
            icon-left="log-out"
            @click.prevent="logout"
          >
            {{ $t('siteheader.account.log_out') }}
          </MButton>
        </MMobileNavItem>
      </template>
    </MMobileNav>
    <MSiteHeaderLayout>
      <template #logo>
        <div class="site-header__logo">
          <router-link to="/">
            <Logo :width="150" />
          </router-link>
        </div>
      </template>
      <template #nav>
        <MNav grow>
          <template v-if="isLoggedIn">
            <MNavItem
              v-for="item in primaryNavItems"
              :key="item.text"
              class="hidden-xs hidden-xxs"
              :active="isActive(item)"
            >
              <MSubNavItem v-if="item.items" :items="item.items" tag="div">
                {{ item.text }}
              </MSubNavItem>
              <MNavLink v-else tag="router-link" :href="item.href">
                {{ item.text }}
              </MNavLink>
            </MNavItem>
          </template>
        </MNav>
        <MNav>
          <template v-if="isLoggedIn && !auth0.loading.value">
            <MNavItem v-if="!isAdmin">
              <MSpacing right="xxs">
                <MButton
                  v-if="
                    router.currentRoute.value.path !== '/hub/new-claim-form'
                  "
                  to="/hub/new-claim-form"
                  tag="router-link"
                  data-qa-locator="start-claim"
                >
                  New claim
                </MButton>
                <MButton
                  v-else
                  href="/hub/new-claim-form"
                  tag="a"
                  data-qa-locator="start-claim"
                >
                  New claim
                </MButton>
              </MSpacing>
            </MNavItem>
            <MNavItem v-if="!isAdmin">
              <Notifications />
            </MNavItem>
            <MNavItem
              v-if="!isAdmin && isMainBranchStructure"
              class="hidden-xs hidden-xxs"
            >
              <MSpacing left="xxs" right="xxs">
                <SwitchPractice />
              </MSpacing>
            </MNavItem>
            <MNavItem v-else-if="!isAdmin" class="hidden-xs hidden-xxs">
              <MSpacing left="xxs" right="xxs" :title="currentPractice">
                {{ currentPractice.substring(0, 30) }}
              </MSpacing>
            </MNavItem>
            <MNavItem>
              <MDropdown full-height tag="div">
                <template #trigger="{ toggle }">
                  <MNavLink @click="toggle">
                    <MAvatar
                      :src="avatar"
                      fallback
                      size="small"
                      :alt="`${user?.first_name} ${user?.last_name}`"
                    />
                  </MNavLink>
                </template>
                <template #content="{ hide }">
                  <MDropdownContent align="right">
                    <MDropdownLink
                      v-for="item in accountNavItems"
                      :key="item.text"
                      size="large"
                      :href="item.href"
                      :tag="item.tag"
                      :data-qa-locator="item.dataQaLocator"
                      :type="item.type"
                      @click="handleAccountItemClick($event, item, hide)"
                    >
                      <div class="site-header__account-link">
                        {{ item.text }}
                      </div>
                    </MDropdownLink>
                  </MDropdownContent>
                </template>
              </MDropdown>
            </MNavItem>
            <MNavItem class="hidden-lg hidden-md hidden-sm hidden-xl">
              <MButtonIcon
                type="button"
                aria-label="Open Menu"
                data-qa-locator="header-burger-menu-button"
                icon="menu"
                size="h2"
                @click="toggleMobileNav"
              />
            </MNavItem>
          </template>
          <template v-if="!isLoggedIn && !loadingAuth">
            <MNavItem>
              <MButton
                button-style="secondary"
                tag="router-link"
                to="/"
                icon-left="log-in"
              >
                {{ $t('siteheader.account.log_in') }}
              </MButton>
            </MNavItem>
          </template>
        </MNav>
      </template>
    </MSiteHeaderLayout>
  </MNavbar>
</template>

<script lang="ts">
import {
  MNavbar,
  MNav,
  MNavItem,
  MSubNavItem,
  MNavLink,
  MSiteHeaderLayout,
  MAvatar,
  MDropdown,
  MDropdownContent,
  MDropdownLink,
  MMobileNav,
  MMobileNavItem,
  MMobileSubnav,
  MButton,
  MButtonIcon,
  MSpacing,
} from '@boughtbymany/many-patterns'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import Notifications from '@/components/controls/Notifications/Notifications.vue'
import SwitchPractice from '@/components/controls/SwitchPractice/SwitchPractice.vue'
import Logo from '@/components/misc/Logo/Logo.vue'
import { useAuth0 } from '@/composables/useAuth0'
import { useError } from '@/composables/useError'
import { HubUser } from '@/domain/vetPortal/HubUser'
import { useAuth } from '@/store/auth'
import { useFeatureFlags } from '@/store/featureFlags'

export default defineComponent({
  name: 'MainNav',
  components: {
    MNavbar,
    MSiteHeaderLayout,
    MNav,
    MNavItem,
    MSubNavItem,
    MNavLink,
    MAvatar,
    MDropdown,
    MDropdownContent,
    MDropdownLink,
    MMobileNav,
    MMobileNavItem,
    MMobileSubnav,
    MButton,
    MButtonIcon,
    MSpacing,
    Logo,
    SwitchPractice,
    Notifications,
  },
  setup() {
    const { silentError } = useError()
    const { t } = useI18n()
    return {
      loadingAuth: ref(true),
      mobileNavDisplay: ref(false),
      petModalDisplay: ref(false),
      router: useRouter(),
      silentError,
      t,
      auth: useAuth(),
      auth0: useAuth0(),
      featureFlags: useFeatureFlags(),
    }
  },
  computed: {
    accountNavItems(): any[] {
      return [
        {
          text: this.t('siteheader.account.profile'),
          href: this.isAdmin ? '/admin/profile' : '/hub/profile',
          tag: 'router-link',
        },
        ...(!this.isAdmin
          ? [
              {
                text: this.t('siteheader.account.practice'),
                href: '/hub/practice',
                tag: 'router-link',
              },
            ]
          : []),
        {
          text: this.t('siteheader.account.log_out'),
          dataQaLocator: 'logout',
          tag: 'button',
          type: 'button',
          click: this.logout,
        },
      ]
    },
    avatar(): string {
      return this.auth0.user.value?.picture
    },
    isAdmin(): boolean {
      return (
        (this.isLoggedIn && this.auth0.getUserRole() === 'Handler') ||
        this.auth0.getUserRole() === 'SysAdmin'
      )
    },
    isLoggedIn(): boolean {
      return !!this.auth0?.user.value
    },
    isSysAdmin(): boolean {
      return this.auth0.getUserRole() === 'SysAdmin'
    },
    currentPractice(): string {
      return this.auth.currentPractice?.name || ''
    },
    // We've had to use the store directly here because the opsToggle function
    // is not reactive like the store itself. The store may not have initialised
    // at the time we load the site header, unlike normal views.
    isMainBranchStructure(): boolean {
      return this.featureFlags.getFeatureFlagByName('main_branch_structure')
    },
    primaryNavItems(): any[] {
      if (this.isAdmin) {
        return [
          {
            text: this.t('siteheader.admin.practices_user.title'),
            items: [
              {
                text: this.t('siteheader.admin.practices_user.items.practices'),
                href: '/admin/practices',
                tag: 'router-link',
              },
              {
                text: this.t('siteheader.admin.practices_user.items.users'),
                href: '/admin/users',
                tag: 'router-link',
              },
              ...(this.isSysAdmin
                ? [
                    {
                      text: this.t(
                        'siteheader.admin.practices_user.items.admins'
                      ),
                      href: '/admin/users?role=ADMIN',
                      tag: 'router-link',
                    },
                  ]
                : []),
            ],
          },
        ]
      }

      return [
        {
          text: this.t('siteheader.hub.claims.title'),
          href: '/hub/',
          tag: 'router-link',
        },
        {
          text: this.t('siteheader.hub.policies'),
          href: '/hub/find-policy',
          tag: 'router-link',
        },
        {
          text: this.t('siteheader.hub.document_requests'),
          href: '/hub/document-requests',
          tag: 'router-link',
        },
        ...(this.isMainBranchStructure
          ? [
              {
                text: this.t('siteheader.hub.practices'),
                href: '/hub/practices',
                tag: 'router-link',
              },
            ]
          : []),
      ]
    },
    user(): HubUser {
      return this.auth0.hubUser.value
    },
  },
  methods: {
    handleAccountItemClick(e: MouseEvent, item: any, hide: () => void) {
      if (e && item.click) {
        e.preventDefault()
      }
      if (item.click) {
        item.click()
      }
      hide()
    },
    isActive(item: any) {
      return !item
    },
    logout() {
      this.loadingAuth = true
      this.auth0.logout()
    },
    toggleMobileNav() {
      this.mobileNavDisplay = !this.mobileNavDisplay
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@boughtbymany/many-patterns/src/sass/framework/framework';

.site-header {
  &__logo {
    img {
      height: auto;
      width: 100px;
    }

    @include breakpoint(desktop-up) {
      img {
        width: 150px;
      }
    }
  }

  &__account-link {
    min-width: 184px;
  }
}
</style>
