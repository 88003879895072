import { defineStore } from 'pinia'
import { RouteLocationNormalized } from 'vue-router'

interface RouterStore {
  routerHistory: RouteLocationNormalized[]
}

export const useRouter = defineStore('router', {
  state: () =>
    ({
      routerHistory: [] as RouteLocationNormalized[],
    }) as RouterStore,
  getters: {
    previousRoute(): RouteLocationNormalized | null {
      if (!this.routerHistory.length) return null
      return this.routerHistory[this.routerHistory.length - 1]
    },
  },
})
