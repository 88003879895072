import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { reactive, toRefs } from 'vue'

import { useError } from '@/composables/useError'
import {
  PolicyCoverages,
  PolicyCustomer,
} from '@/domain/application/application'
import {
  Cover,
  PetV2,
  PolicyV2,
  PolicyLimitsV2CoverageLimit,
  PolicyLimitsSubCoverage,
  PolicyLimitsCoverage,
} from '@/domain/pio/Policy'
import { formatCurrency } from '@/lib/format'
import { findPolicies, getPolicyDetails } from '@/lib/policy'
import { getPolicyV2 } from '@/services/pio/policyService'

type SetPolicyDetailsPayload = {
  policyId?: string
  policyRef?: string
  petUuid: string
  startDate?: string
  endDate?: string
}

export const usePolicyStore = defineStore('policy', () => {
  const { fatalError } = useError()

  const state = reactive({
    selectedPolicy: <PolicyV2 | undefined>undefined,
    selectedPet: <PetV2 | undefined>undefined,
    customer: <PolicyCustomer | undefined>undefined,
    cover: <Cover | undefined>undefined,
    limits: <PolicyLimitsV2CoverageLimit | undefined>undefined,
    coverages: <PolicyCoverages[]>[],
  })

  const selectPolicy = (policy?: PolicyV2) => {
    state.selectedPolicy = policy
  }

  const setPolicyDetails = async ({
    policyId,
    policyRef,
    petUuid,
    startDate,
    endDate,
  }: SetPolicyDetailsPayload) => {
    try {
      if (policyRef) {
        // TODO: As we no longer have Portal only claims, can we update this?
        // Draft claims does not contain a policy_id so we need to use the policy_ref to get find the policy first
        const { items: policies } = await findPolicies({
          field: policyRef,
          type: 'policyNumber',
        })
        // TODO: Should we filter the pets by the PetUuid instead to get the correct pet policy id?
        policyId = policies[0].pets[0].policy_id
      }

      //No need to call if policy is provided already
      if (!state.selectedPolicy) {
        if (!policyId) {
          throw new Error('Policy id not provided')
        }
        if (!startDate) {
          throw new Error('Start date not provided')
        }
        state.selectedPolicy = await getPolicyV2(policyId, {
          policy_at_date: dayjs(startDate).format('YYYY-MM-DD'),
        })
      }

      const { cover, customer, limits, pet } = await getPolicyDetails(
        state.selectedPolicy,
        petUuid,
        startDate,
        endDate
      )
      state.selectedPet = pet
      state.cover = cover
      state.customer = customer
      state.limits = limits

      const vetFeesCoverage = getVetFeesCoverages()
      if (vetFeesCoverage) {
        const vetFeesCoverageStateObject = {
          balance: formatCurrency(getCoverageBalance(vetFeesCoverage)),
          coverage: vetFeesCoverage,
          name: 'vet_fees', // use the SGP name for ease and future proofing. Will also aid ease of selection in the UI.
          subCoverages: getSubCoveragesBalances(vetFeesCoverage, [
            'complementary_treatment',
            'prescribed_food',
          ]),
        }

        state.coverages.push(vetFeesCoverageStateObject)
      }
    } catch (error: any) {
      fatalError({
        message: `Unable to load policy details`,
        code: 'E2021',
        error,
        extra: {
          data: {
            policy_id: policyId,
            policy_ref: policyRef,
            pet_uuid: petUuid,
            startDate,
            endDate,
          },
        },
      })
    }
  }

  const getCoverage = (coverageNames: string[]): PolicyLimitsCoverage[] => {
    if (!state.limits?.coverages) {
      return []
    }

    return state.limits?.coverages.filter((coverage) =>
      coverageNames.includes(coverage.coverage)
    )
  }

  const getVetFeesCoverages = () => {
    const vetFeesCoverage = getCoverage(['vet_fees', 'vet-fees'])[0]
    if (vetFeesCoverage) {
      return vetFeesCoverage
    }

    return null
  }

  // TODO: Do not include IN PROGRESS in this value
  const getCoverageBalance = (coverage: PolicyLimitsCoverage) => {
    return (
      (coverage.policy_limit -
        coverage.amount_spent -
        coverage.amount_in_progress) /
      100
    )
  }

  // TODO: Do not include IN PROGRESS in this value
  const getSubCoverageBalance = (subCoverage: PolicyLimitsSubCoverage) => {
    return subCoverage.limit_amount
      ? (subCoverage.limit_amount -
          subCoverage.amount_spent -
          subCoverage.amount_in_progress) /
          100
      : 0
  }

  const getSubCoveragesBalances = (
    coverage: PolicyLimitsCoverage,
    subcoverages: PolicyLimitsSubCoverage['name'][]
  ) => {
    const subCoveragesToReturn: {
      balance: string
      subCoverage: PolicyLimitsSubCoverage
    }[] = []

    subcoverages.forEach((subcoverageName) => {
      const subCoverage = coverage.subcoverages.find(
        (subcoverage) => subcoverage.name === subcoverageName
      )
      if (subCoverage) {
        const subcoverageBalance = getSubCoverageBalance(subCoverage)

        if (subcoverageBalance > 0) {
          subCoveragesToReturn.push({
            balance: formatCurrency(subcoverageBalance),
            subCoverage: subCoverage,
          })
        }
      }
    })

    return subCoveragesToReturn
  }

  const reset = () => {
    state.cover = undefined
    state.customer = undefined
    state.limits = undefined
    state.selectedPet = undefined
    state.selectedPolicy = undefined
    state.coverages = []
  }

  return {
    ...toRefs(state),
    selectPolicy,
    setPolicyDetails,
    reset,
  }
})
