import { defineStore } from 'pinia'

export const useFeatureFlags = defineStore('featureFlags', {
  state: () => ({ featureFlags: {} }) as Record<string, any>,
  actions: {
    setFeatureFlags(featureFlags: any) {
      this.featureFlags = { ...this.featureFlags, ...featureFlags }
    },
  },
  getters: {
    getFeatureFlags: (state) => state.featureFlags,
    getFeatureFlagByName: (state) => (flag: string) => state.featureFlags[flag],
  },
})
