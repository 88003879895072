import { useModal } from '@boughtbymany/many-patterns'
import { captureException, captureMessage } from '@sentry/vue'

import { heapTrack } from '@/helpers/tracking'
import { PioError, AppSyncError } from '@/lib/Errors'

type CustomError = Error & {
  response: any
  statusText: string
  status: string
  url: string
  json?: () => Promise<unknown>
  text?: () => Promise<string>
}

const isPioError = (arg: unknown): arg is PioError =>
  arg instanceof Error && 'errors' in arg

const isAppSyncError = (arg: unknown): arg is AppSyncError =>
  arg instanceof Error && 'errors' in arg && 'path' in arg

const isCustomError = (arg: unknown): arg is CustomError =>
  arg instanceof Error && 'response' in arg

type ExtraContext = {
  message?: string
  response_status_code?: string
  response_url?: string
  response_json?: unknown
  response_text?: string
  body?: string
  data?: { [key: string]: string | number | boolean | undefined }
  errors?: string
}

type TagsContext = {
  error_code?: string
  response_status_code?: string
  policy_id?: string
  pet_uuid?: string
  portal_claim_uuid?: string
  claim_uuid?: string
  loss_uuid?: string
  portal_company_uuid?: string
  [key: string]: string | undefined
}

export type ErrorArgs = {
  message: string
  context?: string
  error: CustomError | AppSyncError | PioError | Error
  messagePrefix?: string
  code?: string
  tags?: TagsContext
  extra?: ExtraContext
  logToSentry?: boolean
  logToHeap?: boolean
  logException?: boolean
  showTechnicalDetail?: boolean
}
interface UseErrorReturn {
  fatalError: (args: ErrorArgs) => void
  silentError: (args: ErrorArgs) => void
}

/**
 * useError composable.
 * @returns {Function} composable.fatalError - Fatal error logger
 * @example
 * import { useError } from '@/composables/useError'
 * const { fatalError } = useError()
 * fatalError({
 *   message: 'Something went wrong',
 *   error: new Error('Something went wrong'),
 *   code: 'SOMETHING_WENT_WRONG',
 *   tags: {
 *     error_code: 'SOMETHING_WENT_WRONG',
 *   },
 *   extra: {
 *     message: 'Something went wrong',
 *   },
 * })
 */
export const useError = (): UseErrorReturn => {
  const modal = useModal()

  const sendErrorToTrackers = (
    args: ErrorArgs,
    redirectToErrorPage: boolean
  ) => {
    const {
      message,
      extra,
      tags,
      code,
      error,
      logToSentry,
      logToHeap,
      messagePrefix,
      logException,
      showTechnicalDetail,
    } = args
    const extraContext: ExtraContext = {
      ...extra,
      message,
    }

    const tagsContext: TagsContext = {
      ...tags,
      error_code: code,
    }

    // If the error has come from an API call, populate
    //  extra data for Sentry
    if (isCustomError(error)) {
      extraContext.message = error.response?.body?.detail || message

      if (error?.response?.body) {
        extraContext.body = JSON.stringify(error.response.body)
        extraContext.response_status_code = error.response.body?.status
      }

      if (error?.response?.data) {
        extraContext.data = error.response.data
      }

      extraContext.response_url = error.response?.url

      tagsContext.response_status_code = error.response?.status
    }

    console.group(`Error (${code}): ${extraContext.message}`)
    console.error(extraContext.message, error)
    console.groupEnd()

    // Log to Sentry
    if (logToSentry) {
      let httpError
      let httpErrorMessage = ''
      if (isCustomError(error)) {
        httpError = {
          httpStatusCode: error?.status ?? extraContext?.response_status_code,
          httpResponseBody: error?.response?.body,
          requestedUrl: error?.url,
        }
        httpErrorMessage = `Request failed ${
          httpError.httpStatusCode
            ? `with HTTP ${httpError.httpStatusCode}`
            : ''
        } - `
      }

      if (isPioError(error)) {
        extraContext.errors = JSON.stringify(error.errors)
      }

      if (isAppSyncError(error)) {
        extraContext.errors = JSON.stringify(error.errors)
      }

      // Capture our own errors as a preference
      const customMessage = `${code ? `(${code}) ` : ''}${
        messagePrefix ? `${messagePrefix} - ` : ''
      }${httpErrorMessage}${message ? message : extraContext?.message}`

      const sentryDetails = {
        customMessage,
        ...extraContext,
        ...httpError,
        error,
      }

      if (!logException) {
        captureMessage(customMessage, (captureContext) => {
          captureContext.setExtra('extra', sentryDetails)
          captureContext.setTags(tagsContext)
          return captureContext
        })
      } else {
        captureException(error, (captureContext) => {
          captureContext.setExtra('extra', sentryDetails)
          captureContext.setTags(tagsContext)
          return captureContext
        })
      }
    }

    // Log to Heap
    if (logToHeap) {
      const properties = {
        code,
        message,
        error_message: error?.message ?? '',
        status_code: '',
        request_url: '',
        extra_context_message: '',
        ...tagsContext,
      }

      if (isCustomError(error)) {
        properties.status_code = error?.statusText
        properties.request_url = error?.url
      }

      heapTrack({
        event: 'Handled error',
        properties,
      })
    }

    // Redirect to error page
    if (redirectToErrorPage) {
      modal.show({
        preset: 'alert',
        type: 'error',
        title: 'Error',
        text: `Error (${code}): ${extraContext.message}. ${
          showTechnicalDetail ? error?.message : ''
        }`,
      })
    }
  }

  /**
   * Fatal error logger.
   * Logs error to Sentry, Heap and redirects to error page.
   * @param args - Fatal error arguments
   * @param args.message - Error message
   * @param args.messagePrefix - Error message prefix
   * @param args.error - Error object
   * @param args.code - Error code
   * @param args.tags - Sentry tags
   * @param args.extra - Sentry extra
   * @param args.logToSentry - Log to Sentry (default: true)
   * @param args.logToHeap - Log to Heap (default: true)
   * @param args.logException - Log as exception (default: true)
   */
  const fatalError = (args: ErrorArgs) => {
    const logToSentry = args.logToSentry ?? true
    const logToHeap = args.logToHeap ?? true
    const logException = args.logException ?? true

    sendErrorToTrackers(
      {
        ...args,
        logToSentry,
        logToHeap,
        logException,
      },
      true
    )
  }

  /**
   * Silent error logger.
   * Logs error to Sentry and Heap.
   * @param args - Fatal error arguments
   * @param args.message - Error message
   * @param args.messagePrefix - Error message prefix
   * @param args.error - Error object
   * @param args.code - Error code
   * @param args.tags - Sentry tags
   * @param args.extra - Sentry extra
   * @param args.logToSentry - Log to Sentry (default: true)
   * @param args.logToHeap - Log to Heap (default: true)
   * @param args.logException - Log as exception (default: false)
   */
  const silentError = (args: ErrorArgs) => {
    const logToSentry = args.logToSentry ?? true
    const logToHeap = args.logToHeap ?? true
    const logException = args.logException ?? false

    sendErrorToTrackers(
      {
        ...args,
        logToSentry,
        logToHeap,
        logException,
      },
      false
    )
  }

  return {
    fatalError,
    silentError,
  }
}
