import { captureException, captureMessage } from '@sentry/vue'

export const heapTrack = ({
  location,
  event,
  properties,
}: {
  location?: string
  event: string
  properties?: HeapTrackingProperties
}): void => {
  const eventName = [location, event].filter((s) => !!s).join(' - ')
  let index = 1
  let tracked = false

  function sendEventToHeap() {
    if (window.heap?.track) {
      tracked = true
      try {
        window.heap.track(eventName, properties)
      } catch (error) {
        console.error(error)
        captureException(error, {
          extra: { eventName, eventProperties: properties },
        })
      }
    }
  }

  sendEventToHeap()
  if (!tracked) {
    // Wait for Heap to exist on window
    // If it never shows up, crash out after 5 seconds
    const heapInterval = setInterval(() => {
      if (window.heap?.track) {
        clearInterval(heapInterval)
        sendEventToHeap()
      } else if (index > 5) {
        clearInterval(heapInterval)
        captureMessage('Heap not loaded after 5 seconds.')
      }
      index++
    }, 1000)
  }
}

export const heapIdentify = (userId: string): void => {
  if (window.heap?.identify) {
    window.heap.identify(userId)
  }
}

export const heapAddUserProperties = (
  properties: HeapTrackingProperties
): void => {
  if (window.heap?.addUserProperties) {
    window.heap.addUserProperties(properties)
  }
}

/**
 * Identifies an user by vet practice in clarity
 * @param {string} practiceId the vet practice to identify this user
 */
export const clarityIdentify = (practiceId: string) => {
  try {
    //Identify users by email in clarity
    if (window.clarity) {
      window.clarity('identify', practiceId)
    }
  } catch (err) {
    if (err instanceof Error) {
      console.error(err.message)
    }
  }
}
