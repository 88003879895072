import {
  DocumentRequestUploadUrlRequest,
  DocumentRequestUploadUrlResponse,
  DocumentRequestUploadRequest,
  DocumentRequestResponse,
  DocumentRequest,
  Document,
} from '@/domain/pio/DocumentRequest'
import { API } from '@/lib/api'

const deserialiseResponse = (response: Response): any => {
  if (!response.status || response.status < 300) {
    return response
  }

  throw new Error(`Request failed with ${response?.status}`)
}

export const getDocumentRequest = async (
  documentRequestId: string
): Promise<DocumentRequest | Response> => {
  const res = await new API().get('GET_DOCUMENT_REQUEST', {
    pathOptions: {
      document_request_id: documentRequestId,
    },
  })

  return deserialiseResponse(res)
}

export const getDocumentRequests = async (
  vetPracticeId: string
): Promise<DocumentRequestResponse> => {
  if (!vetPracticeId) {
    throw new Error('Vet Practice id not provided')
  }

  const params = {
    exclude_expired: true,
    exclude_fulfilled: true,
  }

  return await new API()
    .get(`GET_DOCUMENT_REQUESTS`, { queryParams: params })
    .then((res) => ({
      items: res?._embedded?.items,
      meta: res?._meta,
    }))
}

export const getDocumentRequestsForOwner = async (
  owner: string
): Promise<DocumentRequestResponse> => {
  if (!owner) {
    throw new Error('Owner not provided')
  }

  const params = {
    owner,
  }

  return await new API()
    .get(`GET_DOCUMENT_REQUESTS_FOR_OWNER`, { queryParams: params })
    .then((res) => ({
      items: res?._embedded?.items,
      meta: res?._meta,
    }))
}

export const getDocumentRequestsForClaim = async (
  claimId: string
): Promise<DocumentRequestResponse> => {
  if (!claimId) {
    throw new Error('Claim Id not provided')
  }

  const params = {
    exclude_expired: true,
    exclude_fulfilled: true,
  }

  return await new API()
    .get(`GET_DOCUMENT_REQUESTS_FOR_CLAIM`, {
      pathOptions: { claim_id: claimId },
      queryParams: params,
    })
    .then((res) => ({
      items: res?._embedded?.items,
      meta: res?._meta,
    }))
}

export const makeDocumentRequestUploadUrl = async (
  id: string,
  mimeType: string
): Promise<DocumentRequestUploadUrlResponse> => {
  const body: DocumentRequestUploadUrlRequest = {
    mime_type: mimeType,
  }

  return new API().post('POST_DOCUMENT_REQUEST_UPLOAD_URL', {
    pathOptions: { document_request_id: id },
    body,
  })
}

export const postDocumentRequestDocuments = async (
  id: string,
  payload: DocumentRequestUploadRequest | Document
): Promise<DocumentRequestResponse | Response> => {
  const res = await new API().post('POST_DOCUMENT_REQUEST_FULFIL', {
    pathOptions: { document_request_id: id },
    body: payload,
  })

  return deserialiseResponse(res)
}

export const putFile = async (
  presignedUrl: string,
  fields: Record<string, any>,
  file: File
) => {
  const formData = new FormData()
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key])
  })

  formData.append('file', file)

  const response = await fetch(presignedUrl, {
    method: 'POST',
    body: formData,
  })

  if (response.status > 299) {
    throw new Error(
      `Request failed with ${response.status} ${response.statusText}`
    )
  }

  return response
}

export default {
  getDocumentRequest,
  getDocumentRequests,
  makeDocumentRequestUploadUrl,
  postDocumentRequestDocuments,
  putFile,
}
